/* Colors for storm.pub website */
:root {
  --midnight-black: #000000;
  --almost-black: rgb(24, 24, 24);
  --almost-black-transparent: rgba(24, 24, 24, 0.8);
  --dark-gray: #3b3b3b;
  --gray: #808080;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

/* set the height of the div with the id root  to 100vh */
#root {
  height: 100vh;
}

/* Background colors */
body {
  background-color: var(--midnight-black);
  width: 100%;
  height: 100%;
}

/*  make background semi transparent, almost black */
.homerow {
  background-color: var(--almost-black-transparent);
  backdrop-filter: blur(3px);
  width: 100%;
}

/* Header */
header {
  background-color: var(--midnight-black);
}

h1 {
  margin-top: 0px;
}

h2 {
  margin-top: 0px;
}

h3 {
  margin-top: 0px;
}

h4 {
  margin-top: 0px;
}

h5 {
  margin-top: 0px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.card {
  background-color: var(--almost-black);
  border-radius: 1rem;
  padding: 1rem;
}

.bg-secondary {
  background-color: var(--almost-black);
}

.border-bottom {
  border-bottom: 1px solid var(--dark-gray);
}

.m-0 {
  margin: 0;
}

.mb-half {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.m-1 {
  margin: 1rem;
}

.p-1 {
  padding: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.p-0 {
  padding: 0;
}

.drop-shadow {
  box-shadow: 0 0 10px rgba(161, 161, 161, 0.5);
  border-radius: 1rem;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.storm-icon {
  fill: blue;
}

.text-secondary {
  color: var(--gray);
}

.text-center {
  text-align: center;
}

.background {
  background-color: var(--midnight-black);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}
